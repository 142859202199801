import React from 'react';
import { Container } from '@mui/material';

const SectionContainer = ({ className, children, sx = {} }) => {
  return (
    <Container className={className} disableGutters sx={{ ...sx }}>
      {children}
    </Container>
  );
};

export default SectionContainer;
