import React from 'react';
import SectionContainer from '../common/SectionContainer';
import { Box, Container, Grid, Stack, Typography, styled } from '@mui/material';

const awsData = [
  {
    title: '업무 속도 향상',
    desc: '사용량에 따른 클라우드 서버를 제공하여\n일정한 QoS를 보장합니다.',
    img: `${process.env.PUBLIC_URL}/images/aws/aws_ico_01.png`,
  },
  {
    title: '안전한 보안성',
    desc: '사용자의 데이터를 암호화하여 저장하고\nAWS에서 제안하는 보안 및 규정을 준수합니다.',
    img: `${process.env.PUBLIC_URL}/images/aws/aws_ico_02.png`,
  },
  {
    title: '개인 IP 할당',
    desc: '사용자별 독립적인 IP를 할당하여\n마켓 연동 과정의 문제를 최소화 합니다.',
    img: `${process.env.PUBLIC_URL}/images/aws/aws_ico_03.png`,
  },
];

const Aws = () => {
  return (
    <Container maxWidth={'false'} sx={{ background: '#313749' }}>
      <SectionContainer>
        <Stack alignItems={'center'} pt={'80px'}>
          <Box
            component={'img'}
            sx={{
              width: { md: '80px', xs: '60px' },
            }}
            src={`${process.env.PUBLIC_URL}/images/aws/aws_logo.png`}
          />
          <Stack alignItems={'center'} mt={'18px'}>
            <Typography
              fontSize={{ md: '42px', xs: '24px' }}
              fontWeight={300}
              lineHeight={{ md: '56px', xs: '28.64px' }}
              color={'#FFFFFF'}
            >
              {'빠르고 안전한 서비스를 위해'}
            </Typography>
            <Typography
              fontSize={{ md: '42px', xs: '24px' }}
              fontWeight={700}
              lineHeight={{ md: '50.12px', xs: '28.64px' }}
              color={'#FFFFFF'}
            >
              {'개인별 AWS 서버 제공'}
            </Typography>
          </Stack>
          <Grid
            container
            spacing={2}
            mt={{ md: '80px', xs: '40px' }}
            mb={{ md: '200px', xs: '80px' }}
          >
            {awsData?.map((data, idx) => {
              return (
                <Grid key={idx} item md={4} xs={12} mb={'20px'}>
                  <AwsCard
                    title={data.title}
                    desc={data.desc}
                    img={data.img}
                    sx={{
                      background: '#202532',
                      borderRadius: '20px',
                      minHeight: { md: '240px', xs: '200px' },
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </SectionContainer>
    </Container>
  );
};

const InnerShadowBox = styled(Box)({
  position: 'absolute',
  top: '-5px',
  width: 'calc(100%)',
  height: 'calc(100% + 10px)',
  borderRadius: '20px',
  boxShadow: 'inset 0 4px 0.5px rgba(255, 255, 255, 0.5)', // 내부 그림자 적용
});

const AwsCard = ({ title, desc, img, sx }) => {
  return (
    <Box position="relative">
      <InnerShadowBox />
      <Stack
        sx={{ ...sx }}
        justifyContent={'space-between'}
        boxShadow={'0 4px 4px rgba(0, 0, 0, 0.25)'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          pt={'24px'}
          pl={{ md: '40px', xs: '20px' }}
          pr={{ md: '30px', xs: '20px' }}
        >
          <Typography
            fontSize={{ md: '30px', xs: '24px' }}
            fontWeight={700}
            lineHeight={{ md: '35px', xs: '28.64px' }}
            color={'#FFFFFF'}
          >
            {title}
          </Typography>
          <Box component={'img'} src={img} width={{ md: '80px', xs: '60px' }} />
        </Box>
        <Typography
          fontSize={'16px'}
          fontWeight={400}
          lineHeight={'24px'}
          whiteSpace={'pre-line'}
          color={'#FFFFFF'}
          pl={{ md: '40px', xs: '20px' }}
          pb={'40px'}
        >
          {desc}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Aws;
