import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';

const LogoWrapper = ({ img }) => (
  <Box
    display={'flex'}
    justifyContent={'center'}
    alignItems={'center'}
    sx={{
      width: { md: '100px', xs: '62px' },
      height: { md: '100px', xs: '62px' },
      borderRadius: '10px',
      background: '#F4F6F9',
    }}
  >
    <Box
      component={'img'}
      sx={{ width: { md: '80px', xs: '48px' } }}
      src={img}
    />
  </Box>
);

const LogoCard = ({
  title,
  subTitle,
  titleImages,
  subTitleImages,
  sx = {},
}) => {
  return (
    <Stack sx={{ ...sx }}>
      {/* Title */}
      <Typography
        fontSize={{ md: '30px', xs: '20px' }}
        fontWeight={700}
        lineHeight={{ md: '35.8px', xs: '23.87px' }}
        pt={{ md: '50px', xs: '30px' }}
        pl={{ md: '40px', xs: '24px' }}
      >
        {title}
      </Typography>
      <Grid
        container
        spacing={2}
        paddingX={{ md: '50px', xs: '24px' }}
        paddingY={{ md: '40px', xs: '18px' }}
        maxWidth={{ md: '550px', xs: '100%' }}
      >
        {[...Array(4)].map((_, idx) => (
          <Grid item key={idx} md={3} xs={3}>
            {titleImages[idx] ? (
              <LogoWrapper img={titleImages[idx]} />
            ) : (
              <LogoWrapper />
            )}
          </Grid>
        ))}
      </Grid>
      {/* subTitle */}
      <Typography
        fontSize={{ md: '20px', xs: '14px' }}
        fontWeight={700}
        lineHeight={{ md: '23.87px', xs: '16.71px' }}
        pl={{ md: '40px', xs: '24px' }}
      >
        {subTitle}
      </Typography>
      <Grid
        container
        spacing={2}
        paddingX={{ md: '50px', xs: '24px' }}
        pt={{ md: '30px', xs: '12px' }}
        pb={{ md: '58px', xs: '35px' }}
        maxWidth={{ md: '550px', xs: '100%' }}
      >
        {[...Array(8)].map((_, idx) => (
          <Grid item key={idx} md={3} xs={3}>
            {subTitleImages[idx] ? (
              <LogoWrapper img={subTitleImages[idx]} />
            ) : (
              <LogoWrapper />
            )}
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default LogoCard;
