import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import PriceList from './Pages/PriceList';
import { useRecoilValue } from 'recoil';
import { modalAtom } from './recoil/modalAtom';
import TermsModal from './components/common/modal/Terms';
import Privacy from './Pages/Policy/Privacy';

const Router = () => {
  const modal = useRecoilValue(modalAtom);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/price" element={<PriceList />} />
        <Route path="/policy/privacy" element={<Privacy />} />
      </Routes>
      {modal.terms && <TermsModal />}
    </BrowserRouter>
  );
};

export default Router;
