import React from 'react';
import SectionContainer from '../common/SectionContainer';
import TitleContainer from '../common/TitleContainer';
import { Grid, Stack, Typography } from '@mui/material';

const serviceData = [
  {
    title: '수집사이트 추가',
    subTitle:
      '원하는 수집사이트가 있을 경우, 문의주시면\n검토 후 수집사이트 추가 가능합니다.',
    img: `${process.env.PUBLIC_URL}/images/service/service_img_01.png`,
  },
  {
    title: '다양한 검색필터 기능-상품관리 기능',
    subTitle: '타사와 차별적으로 더 디테일한\n검색 기능을 제공합니다',
    img: `${process.env.PUBLIC_URL}/images/service/service_img_02.png`,
  },
  {
    title: '문자/카카오로 CS관리',
    subTitle:
      '주문/문의 내역을 확인하고\n문자 템플릿을 사용하여 전송할 수 있습니다.',
    img: `${process.env.PUBLIC_URL}/images/service/service_img_03.png`,
  },
  {
    title: '직원 계정 추가(준비중)',
    subTitle: '직원별로 필요한 기능을 설정하고\n효율적으로 작업이 가능합니다.',
    img: `${process.env.PUBLIC_URL}/images/service/service_img_04.png`,
  },
  {
    title: '배송대행지 관리(준비중)',
    subTitle:
      '빠르고 간편하게 배송대행 신청서를 작성하여\n업무 시간을 단축시킬 수 있습니다.',
    img: `${process.env.PUBLIC_URL}/images/service/service_img_05.png`,
  },
  {
    title: '자사몰 지원(준비중)',
    subTitle: '경쟁력있는 다양한 자사몰에서\n상품을 수집할 수 있습니다.',
    img: `${process.env.PUBLIC_URL}/images/service/service_img_06.png`,
  },
  {
    title: '카테고리 분석(준비중)',
    subTitle:
      '키워드별 경쟁률, 쇼핑전환율,\n월 검색량, 상품량 등을 제공합니다.',
    img: `${process.env.PUBLIC_URL}/images/service/service_img_07.png`,
  },
  {
    title: '상품 유입수 분석(준비중)',
    subTitle: '상품별 유입수를 조회하여\n효과적으로 관리할 수 있습니다.',
    img: `${process.env.PUBLIC_URL}/images/service/service_img_08.png`,
  },
];

const Service = () => {
  return (
    <SectionContainer>
      <TitleContainer title={'그 외 다양한 기능'} subTitle={'Service'}>
        {
          <Grid container spacing={2} mb={'130px'}>
            {serviceData?.map((data, idx) => (
              <Grid
                item
                key={idx}
                lg={6}
                md={6}
                xs={12}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <ServiceImageCard
                  title={data.title}
                  desc={data.subTitle}
                  img={data.img}
                  sx={{
                    background: '#F4F6F9',
                    width: '90%',
                    height: '260px',
                    borderRadius: '10px',
                  }}
                />
              </Grid>
            ))}
          </Grid>
        }
      </TitleContainer>
    </SectionContainer>
  );
};

const ServiceImageCard = ({ title, desc, img, sx }) => {
  return (
    <Stack sx={{ ...sx }} justifyContent={'space-between'}>
      <Stack>
        <Typography
          fontSize={{ md: '24px', xs: '20px' }}
          fontWeight={700}
          lineHeight={{ md: '28.64px', xs: '23.87px' }}
          color={'#0E0D33'}
          pl={{ md: '40px', xs: '25px' }}
          pt={{ md: '40px', xs: '30px' }}
        >
          {title}
        </Typography>
        <Typography
          fontSize={{ md: '18px', xs: '14px' }}
          fontWeight={400}
          lineHeight={{ md: '24px', xs: '20px' }}
          whiteSpace={'pre-line'}
          color={'#3E3D5B'}
          pl={{ md: '40px', xs: '25px' }}
          pt={{ md: '13px', xs: '8px' }}
        >
          {desc}
        </Typography>
      </Stack>
      <Stack
        component={'img'}
        src={img}
        alignSelf={'flex-end'}
        paddingX={'30px'}
        pb={'20px'}
        maxWidth={{ md: '548px', xs: '260px' }}
      />
    </Stack>
  );
};

export default Service;
