import React from "react";

import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import TitleContainer from "../common/TitleContainer";

const reviewData = [
  {
    title: "타사와 비교 불가 기능!!",
    desc: "많은 대량/반자동 프로그램을 비교 대조해본 결과 Spresso 만한게 없는거 같더라구요~ 앞으로도 더 좋은 환경을 제공해주시길 부탁드립니다. Spresso 화이팅!! 구매대행 화이팅!! 초초초 대박입니다!!",
    reviewer: "김OO 대표님",
  },
  {
    title: "타사와 비교 불가 기능!!",
    desc: "많은 대량/반자동 프로그램을 비교 대조해본 결과 Spresso 만한게 없는거 같더라구요~ 앞으로도 더 좋은 환경을 제공해주시길 부탁드립니다. Spresso 화이팅!! 구매대행 화이팅!! 초초초 대박입니다!!",
    reviewer: "김OO 대표님",
  },
  {
    title: "타사와 비교 불가 기능!!",
    desc: "많은 대량/반자동 프로그램을 비교 대조해본 결과 Spresso 만한게 없는거 같더라구요~ 앞으로도 더 좋은 환경을 제공해주시길 부탁드립니다. Spresso 화이팅!! 구매대행 화이팅!! 초초초 대박입니다!!",
    reviewer: "김OO 대표님",
  },
  {
    title: "타사와 비교 불가 기능!!",
    desc: "많은 대량/반자동 프로그램을 비교 대조해본 결과 Spresso 만한게 없는거 같더라구요~ 앞으로도 더 좋은 환경을 제공해주시길 부탁드립니다. Spresso 화이팅!! 구매대행 화이팅!! 초초초 대박입니다!!",
    reviewer: "김OO 대표님",
  },
  {
    title: "타사와 비교 불가 기능!!",
    desc: "많은 대량/반자동 프로그램을 비교 대조해본 결과 Spresso 만한게 없는거 같더라구요~ 앞으로도 더 좋은 환경을 제공해주시길 부탁드립니다. Spresso 화이팅!! 구매대행 화이팅!! 초초초 대박입니다!!",
    reviewer: "김OO 대표님",
  },
  {
    title: "타사와 비교 불가 기능!!",
    desc: "많은 대량/반자동 프로그램을 비교 대조해본 결과 Spresso 만한게 없는거 같더라구요~ 앞으로도 더 좋은 환경을 제공해주시길 부탁드립니다. Spresso 화이팅!! 구매대행 화이팅!! 초초초 대박입니다!!",
    reviewer: "김OO 대표님",
  },
];

const Review = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickLogin = () => {
    window.location.href = "https://console.spresso.kr/login";
  };

  const settings_md = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    centerPadding: "25%",
  };

  const settings_xs = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    centerPadding: "10%",
  };

  return (
    <Container maxWidth={false} sx={{ background: "#F4F6F9" }}>
      <TitleContainer title={"고객 이용 후기"}>
        <Box className="slider-container">
          {isMobile ? (
            <Slider {...settings_xs}>
              {reviewData?.map((data, idx) => (
                <ReviewCard
                  key={idx}
                  title={data.title}
                  desc={data.desc}
                  reviewer={data.reviewer}
                  sx={{
                    background: "#FFFFFF",
                    width: "90%",
                    height: "280px",
                    top: "30px",
                    left: "15px",
                  }}
                />
              ))}
            </Slider>
          ) : (
            <Slider {...settings_md}>
              {reviewData?.map((data, idx) => (
                <ReviewCard
                  key={idx}
                  title={data.title}
                  desc={data.desc}
                  reviewer={data.reviewer}
                  sx={{
                    background: "#FFFFFF",
                    width: "95%",
                    height: "500px",
                    top: "30px",
                    left: "40px",
                  }}
                />
              ))}
            </Slider>
          )}
        </Box>
        {/* 하단 무료체험 배너 */}
        <Stack
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              width: "90%",
              borderRadius: "10px",
              mt: { md: "100px", xs: "61px" },
              mb: "48px",
              alignItems: "center",
              background: "#441CF2",
            }}
          >
            <Stack sx={{ pt: { md: "56px", xs: "58px" } }}>
              <Typography
                fontSize={"14px"}
                fontWeight={400}
                lineHeight={"16.71px"}
                color={"#ffffff"}
              >
                아직 고민중이신가요?
              </Typography>
            </Stack>
            <Stack sx={{ pt: "15px" }}>
              <Typography
                fontSize={{ md: "30px", xs: "24px" }}
                fontWeight={700}
                lineHeight={{ md: "35.8px", xs: "28.64px" }}
                color={"#ffffff"}
                display={{ md: "block", xs: "none" }}
              >
                2주간 무료로 사용해보고 결정하세요!
              </Typography>
              <Typography
                fontSize={{ md: "30px", xs: "24px" }}
                fontWeight={700}
                lineHeight={{ md: "35.8px", xs: "28.64px" }}
                color={"#ffffff"}
                whiteSpace={"pre-line"}
                textAlign={"center"}
                display={{ md: "none" }}
              >
                {"2주간 무료로 사용해보고\n결정하세요!"}
              </Typography>
            </Stack>
            <Stack
              sx={{
                width: { md: "200px", xs: "180px" },
                height: { md: "56px", xs: "42px" },
                borderRadius: "30px",
                background: "#ffffff",
                justifyContent: "center",
                alignItems: "center",
                mt: { md: "42px", xs: "34px" },
                mb: { md: "74px", xs: "55px" },
              }}
              onClick={handleClickLogin}
            >
              <Typography
                fontSize={"18px"}
                fontWeight={400}
                lineHeight={"21.48px"}
                color={"#441CF2"}
              >
                2주 무료체험
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </TitleContainer>
    </Container>
  );
};

const ReviewCard = ({ title, desc, reviewer, sx = {} }) => {
  return (
    <Stack
      position={"relative"}
      justifyContent={"space-between"}
      sx={{
        ...sx,
        // '&::before, &::after': {
        //   content: '""',
        //   position: 'absolute',
        //   top: 0,
        //   bottom: 0,
        //   width: 200, // 그라데이션 너비 조절
        //   zIndex: 1,
        //   pointerEvents: 'none',
        // },
        // '&::before': {
        //   left: 0,
        //   background: `linear-gradient(to right, #000000, transparent)`,
        // },
        // '&::after': {
        //   right: 0,
        //   background: `linear-gradient(to left, #000000, transparent)`,
        // },
      }}
    >
      <Stack
        mt={{ md: "100px", xs: "40px" }}
        paddingX={{ md: "80px", xs: "20px" }}
      >
        <Typography
          fontSize={{ md: "30px", xs: "20px" }}
          fontWeight={700}
          lineHeight={"30px"}
        >
          {title}
        </Typography>
        <Typography
          fontSize={{ md: "21px", xs: "14px" }}
          fontWeight={400}
          lineHeight={{ md: "36px", xs: "18px" }}
          mt={{ md: "32px", xs: "10px" }}
          whiteSpace={"pre-wrap"}
        >
          {desc}
        </Typography>
      </Stack>
      <Stack>
        <Typography
          fontSize={{ md: "21px", xs: "14px" }}
          fontWeight={400}
          lineHeight={{ md: "30px", xs: "20px" }}
          paddingX={{ md: "80px", xs: "20px" }}
          alignSelf={"flex-end"}
          pb={{ md: "95px", xs: "40px" }}
        >
          {reviewer}
        </Typography>
      </Stack>
      <Box
        component={"img"}
        src={`${process.env.PUBLIC_URL}/images/review/like-tag.png`}
        sx={{
          position: "absolute",
          width: { md: "70px", xs: "50px" },
          height: { md: "70px", xs: "50px" },
          top: { md: "-30px", xs: "-25px" },
          left: { md: "70px", xs: "25px" },
        }}
      />
    </Stack>
  );
};

export default Review;
