import { Stack, Typography } from '@mui/material';
import React from 'react';

const ImageCard = ({ img, title, desc }) => {
  return (
    <Stack
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      maxWidth={{ md: '300px', xs: '165px' }}
    >
      <Stack
        pt={{ md: '73px', xs: '13px' }}
        component={'img'}
        src={img}
        sx={{ width: { md: '72px', xs: '38px' } }}
      />
      <Stack mt={{ md: '44px', xs: '22px' }}>
        <Typography
          fontSize={{ md: '24px', xs: '16px' }}
          fontWeight={700}
          lineHeight={{ md: '28.64px', xs: '19.09px' }}
          color={'#0E0D33'}
        >
          {title}
        </Typography>
      </Stack>
      <Stack mt={{ md: '14px', xs: '8px' }}>
        <Typography
          fontSize={{ md: '15px', xs: '14px' }}
          fontWeight={400}
          lineHeight={'22px'}
          color={'#0E0D33'}
          whiteSpace={'pre-line'}
          pb={'29px'}
          textAlign={'center'}
        >
          {desc}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ImageCard;
