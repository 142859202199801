import React from 'react';
import SectionContainer from '../common/SectionContainer';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';

const extraServiceList = [
  '수집 상품 개수 추가',
  '신규 사이트 세팅',
  '마켓 계정 추가',
  '이미지 호스팅',
  '배송대행지',
  '텍스트 번역',
];

const ExtraService = () => {
  return (
    <Container maxWidth={false} sx={{ background: '#F4F1FF' }}>
      <SectionContainer>
        <Stack alignItems={'center'}>
          <Stack direction={'row'} mt={{ md: '59px', xs: '50px' }}>
            <Typography
              fontSize={{ md: '42px', xs: '24px' }}
              fontWeight={700}
              lineHeight={{ md: '50.12px', xs: '24.64px' }}
            >
              부가서비스
            </Typography>
            <Typography
              fontSize={{ md: '42px', xs: '24px' }}
              fontWeight={400}
              lineHeight={{ md: '50.12px', xs: '24.64px' }}
              sx={{ ml: '10px' }}
            >
              안내
            </Typography>
          </Stack>
          <Stack sx={{ mt: '37px', mb: '94px' }} alignItems={'center'}>
            <Grid container spacing={2} width={{ md: '60%', xs: '100%' }}>
              {extraServiceList?.map((ser, idx) => (
                <Grid
                  key={idx}
                  item
                  md={4}
                  xs={6}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                      width: { md: '188px', xs: '160px' },
                      height: '50px',
                      borderRadius: '5px',
                      background: '#EAEAFD',
                    }}
                  >
                    <Typography
                      fontSize={{ md: '16px', xs: '16px' }}
                      fontWeight={700}
                      lineHeight={{ md: '24px', xs: '24px' }}
                      color={'#441CF2'}
                    >
                      {ser}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Stack>
      </SectionContainer>
    </Container>
  );
};

export default ExtraService;
