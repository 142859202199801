import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import SectionContainer from "../common/SectionContainer";

const Introduction = () => {
  const handleClickLogin = () => {
    window.location.href = "https://console.spresso.kr/login";
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        background: "linear-gradient(to top, #E9EAF6, transparent 100%)",
      }}
    >
      <SectionContainer>
        <Box alignItems={"center"} display={"flex"} flexDirection={"column"}>
          {/* 위쪽 박스 */}
          <Stack>
            <Box
              display={{ md: "flex" }}
              alignItems={"center"}
              mt={{ md: "100px", xs: "42px" }}
            >
              <Box sx={{ maxWidth: "fit-contents" }}>
                <Box
                  component="img"
                  sx={{
                    width: { md: "80%", xs: "130px" },
                    height: "100%",
                  }}
                  src={`${process.env.PUBLIC_URL}/images/introduction/bulb.png`}
                ></Box>
              </Box>
              <Box>
                <Stack>
                  <Typography
                    fontSize={{ md: "42px", xs: "30px" }}
                    fontWeight={700}
                    align="center"
                    lineHeight={{ md: "50.12px", xs: "35.8px" }}
                    whiteSpace={"pre-line"}
                  >
                    {"구매대행의 모든 것,\n Spresso에서 한번에"}
                  </Typography>
                </Stack>
                <Stack mt={"18px"}>
                  <Typography
                    fontSize={{ md: "24px", xs: "16px" }}
                    fontWeight={700}
                    align="center"
                    lineHeight={{ md: "28.64px", xs: "19.09px" }}
                    color={"#878698"}
                  >
                    수집, 상품, 주문, 문의까지 한번에 관리하세요.
                  </Typography>
                </Stack>
                <Stack>
                  <Button
                    sx={{
                      bgcolor: "#441CF2",
                      width: "200px",
                      height: "56px",
                      borderRadius: "30px",
                      alignSelf: "center",
                      mt: "36px",
                    }}
                    onClick={handleClickLogin}
                  >
                    <Typography
                      color={"white"}
                      fontSize={18}
                      fontWeight={700}
                      align="center"
                      lineHeight={"21.48px"}
                    >
                      2주 무료체험
                    </Typography>
                  </Button>
                </Stack>
              </Box>
              <Box display={"flex"} justifyContent={{ md: "", xs: "flex-end" }}>
                <Box
                  component="img"
                  sx={{
                    width: { md: "300px", xs: "130px" },
                    height: "100%",
                  }}
                  src={`${process.env.PUBLIC_URL}/images/introduction/speech_bubble.png`}
                ></Box>
              </Box>
            </Box>
          </Stack>
          {/* 아래쪽 박스 */}
          <Stack mt={5} position={"relative"}>
            <Box display={"flex"} justifyContent={"center"}>
              <Box
                component="img"
                sx={{
                  width: { md: "65%", xs: "330px" },
                  height: "100%",
                }}
                style={{ objectFit: true }}
                src={`${process.env.PUBLIC_URL}/images/introduction/dashboardX4.png`}
              />
            </Box>
            <Box
              position={"absolute"}
              left={{ md: 55, xs: -50 }}
              bottom={{ md: 0, xs: 140 }}
            >
              <Box
                component="img"
                sx={{
                  width: { md: "75%", xs: "45%" },
                  height: "100%",
                }}
                src={`${process.env.PUBLIC_URL}/images/introduction/basket.png`}
              />
            </Box>
          </Stack>
        </Box>
      </SectionContainer>
    </Container>
  );
};

export default Introduction;
