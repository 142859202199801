import { useEffect } from 'react';
import {
  Aws,
  Easy,
  Fast,
  Introduction,
  MainFacility,
  Quality,
  Review,
  Service,
} from '../components/home';
import PageContainer from '../components/layout/PageContainer';

const Home = () => {
  useEffect(() => {
    const handleNavigate = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('beforeunload', handleNavigate);

    return () => {
      window.removeEventListener('beforeunload', handleNavigate);
    };
  }, []);

  return (
    <PageContainer>
      <Introduction />
      <MainFacility />
      <Easy />
      <Fast />
      <Aws />
      <Quality />
      <Service />
      <Review />
    </PageContainer>
  );
};

export default Home;
