import React, { useEffect, useState } from 'react';
import SectionContainer from '../common/SectionContainer';
import TitleContainer from '../common/TitleContainer';
import { Box, Stack, useTheme, useMediaQuery, Typography } from '@mui/material';

const images = [
  `${process.env.PUBLIC_URL}/images/fast/fast_img_01.png`,
  `${process.env.PUBLIC_URL}/images/fast/fast_img_02.png`,
  `${process.env.PUBLIC_URL}/images/fast/fast_img_03.png`,
  `${process.env.PUBLIC_URL}/images/fast/fast_img_04.png`,
];

const stepperData = [
  {
    imgs: [
      `${process.env.PUBLIC_URL}/images/fast/math_on.png`,
      `${process.env.PUBLIC_URL}/images/fast/math_off.png`,
    ],
    bg: ['#EDE9FF', '#F4F6F9'],
    title: '마진 정책',
    desc: '예상 운송비, 최소 마진 금액, 마진율,\n관세/부가세 적용 등을 입력하여\n상품 가격을 설정할 수 있습니다.',
  },
  {
    imgs: [
      `${process.env.PUBLIC_URL}/images/fast/shop-add_on.png`,
      `${process.env.PUBLIC_URL}/images/fast/shop-add_off.png`,
    ],
    bg: ['#EDE9FF', '#F4F6F9'],
    title: '마켓 정책',
    desc: '각 마켓의 수수료율, 배송비 조건,\n출고지/반품지 설정, A/S 안내 등을\n설정할 수 있습니다.',
  },
  {
    imgs: [
      `${process.env.PUBLIC_URL}/images/fast/task_on.png`,
      `${process.env.PUBLIC_URL}/images/fast/task_off.png`,
    ],
    bg: ['#EDE9FF', '#F4F6F9'],
    title: '상품/옵션명 정책',
    desc: '상품/옵션명 치환 조건, 조합 조건,\n중복 단어를 제거할 수 있습니다.',
  },
  {
    imgs: [
      `${process.env.PUBLIC_URL}/images/fast/document-like_on.png`,
      `${process.env.PUBLIC_URL}/images/fast/document-like_off.png`,
    ],
    bg: ['#EDE9FF', '#F4F6F9'],
    title: '상세페이지 정책',
    desc: '상품명, 모델명, 썸네일, 스펙, 상품고시정보 등을\n커스텀 마이징할 수 있습니다.',
  },
];

const Fast = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [curIdx, setCurIdx] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const startTimer = () => {
    const interval = setInterval(() => {
      setCurIdx((prev) => {
        if (prev === stepperData.length - 1) return 0;
        else return prev + 1;
      });
    }, 5000);

    return interval;
  };

  const stopTimer = () => {
    clearInterval(intervalId);
    setIntervalId(null);
  };

  const resetTimer = () => {
    stopTimer();
    const newIntervalId = startTimer();
    setIntervalId(newIntervalId);
  };

  useEffect(() => {
    const newIntervalId = startTimer();
    setIntervalId(newIntervalId);

    return () => {
      clearInterval(newIntervalId);
    };
  }, []);

  return (
    <SectionContainer>
      <TitleContainer
        title={'통합정책으로 한번에 상품 등록'}
        subTitle={'Fast'}
        desc={
          '마진, 마켓, 상품/옵션명, 상세페이지 정책을 생성해\n모든 상품에 일괄 처리 후 마켓 등록!'
        }
      >
        {isMobile ? (
          <Box mb={'70px'}>
            {[...Array(stepperData.length)].map((_, idx) => (
              <Stack
                key={idx}
                sx={{
                  ml: '20px',
                }}
              >
                <Stack direction={'row'} mt={'47px'}>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                      width: '60px',
                      height: '60px',
                      background: `#EAEAFD`,
                      borderRadius: '10px',
                    }}
                  >
                    <Box
                      component={'img'}
                      width={'24px'}
                      src={stepperData[idx].imgs[0]}
                    ></Box>
                  </Box>
                  <Stack ml={'21px'}>
                    <Typography
                      fontSize={'18px'}
                      fontWeight={700}
                      lineHeight={'21.48px'}
                      color={'#0E0D33'}
                    >
                      {stepperData[idx].title}
                    </Typography>
                    <Typography
                      mt={'9px'}
                      fontSize={'14px'}
                      fontWeight={400}
                      lineHeight={'16.71px'}
                      color={'#0E0D33'}
                      whiteSpace={'pre-line'}
                    >
                      {stepperData[idx].desc}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack>
                  <Box component={'img'} mt={'10px'} src={images[idx]} />
                </Stack>
              </Stack>
            ))}
          </Box>
        ) : (
          <Box
            display={'flex'}
            mt={'77px'}
            pb={'200px'}
            justifyContent={'space-between'}
            height={'600px'}
          >
            <Stack pt={'10px'} justifyContent={'space-between'}>
              {stepperData?.map((info, idx) => {
                return (
                  <FastStepperCard
                    key={idx}
                    title={info.title}
                    desc={info.desc}
                    icon={curIdx === idx ? info.imgs[0] : info.imgs[1]}
                    background={curIdx === idx ? info.bg[0] : info.bg[1]}
                    idx={idx}
                    setIdx={setCurIdx}
                    resetTimer={resetTimer}
                    sx={{ cursor: 'pointer' }}
                  />
                );
              })}
            </Stack>
            <Stack>
              <Box component={'img'} src={images[curIdx]}></Box>
            </Stack>
          </Box>
        )}
      </TitleContainer>
    </SectionContainer>
  );
};

const FastStepperCard = (props) => {
  const { title, desc, icon, background, idx, setIdx, resetTimer, sx } = props;

  return (
    <Box display={'flex'} alignItems={'top'}>
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          ...sx,
          width: '100px',
          height: '100px',
          background: `${background}`,
        }}
        onClick={() => {
          setIdx(idx);
          resetTimer();
        }}
      >
        <Box
          component={'img'}
          src={icon}
          sx={{ width: '42px', height: '42px' }}
        />
      </Box>
      <Stack sx={{ height: '100px', ml: '32px' }}>
        <Typography
          fontSize={'24px'}
          fontWeight={700}
          lineHeight={'28.64px'}
          whiteSpace={'pre-line'}
          ml={'20px'}
        >
          {title}
        </Typography>
        <Typography
          fontSize={'14px'}
          fontWeight={400}
          lineHeight={'16.71px'}
          whiteSpace={'pre-line'}
          mt={'14px'}
          ml={'20px'}
          minWidth={'270px'}
        >
          {desc}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Fast;
