import React from 'react';
import SectionContainer from '../common/SectionContainer';
import { Box, Grid, Stack, Typography } from '@mui/material';

const cardInfo = [
  {
    title: '무료 플랜',
    benefit: '14일간 무료',
    price: '0원',
    discount: '',
    originalPrice: '',
    btnText: '2주 무료체험',
  },
  {
    title: '1개월 구독',
    benefit: '총 37,500원 할인',
    price: '237,500/월',
    discount: '5%',
    originalPrice: '250,000원',
    btnText: '구매하기',
  },
  {
    title: '6개월 구독',
    benefit: '총 150,000원 할인',
    price: '225,000/월',
    discount: '10%',
    originalPrice: '250,000원',
    btnText: '구매하기',
  },
  {
    title: '12개월 구독',
    benefit: '총 600,000원 할인',
    price: '200,000/월',
    discount: '20%',
    originalPrice: '250,000원',
    btnText: '구매하기',
  },
];

const checkItems = [
  '무제한 상품 등록',
  '카테고리 매칭',
  '마진 정책',
  '상품 수집',
  'CS 관리',
  '마켓 정책',
  '마켓별 무제한 복수 계정',
  '금지어 설정',
  '상세페이지 정책',
  '무제한 상품 편집',
  '주문 관리',
  '상품/옵션명 정책',
];

const BasePrice = () => {
  return (
    <SectionContainer>
      <Stack alignItems={'center'}>
        {/* Title */}
        <Stack direction={'row'} mt={{ md: '130px', xs: '95px' }}>
          <Typography
            fontSize={{ md: '42px', xs: '24px' }}
            fontWeight={400}
            lineHeight={{ md: '50.12px', xs: '24.64px' }}
          >
            합리적이고
          </Typography>
          <Typography
            fontSize={{ md: '42px', xs: '24px' }}
            fontWeight={700}
            lineHeight={{ md: '50.12px', xs: '24.64px' }}
            sx={{ ml: '10px' }}
          >
            간편한 요금제
          </Typography>
        </Stack>
        {/* descript */}
        <Stack sx={{ mt: '12px' }}>
          <Typography
            fontSize={{ md: '18px', xs: '16px' }}
            fontWeight={400}
            lineHeight={'24px'}
            whiteSpace={'pre-line'}
            textAlign={'center'}
          >{`합리적인 Spresso 요금제를\n부담없이 이용해보세요!`}</Typography>
        </Stack>
        <Grid container spacing={2} sx={{ mt: { md: '46px', xs: '31px' } }}>
          {cardInfo?.map((info, idx) => {
            const { title, benefit, price, discount, originalPrice, btnText } =
              info;
            return (
              <Grid
                item
                key={idx}
                md={3}
                xs={12}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <SubscriptionCard
                  title={title}
                  benefit={benefit}
                  price={price}
                  discount={discount}
                  originalPrice={originalPrice}
                  btnText={btnText}
                  index={idx}
                />
              </Grid>
            );
          })}
        </Grid>
        <Stack alignSelf={{ md: 'flex-start', xs: '' }} sx={{ mt: '27px' }}>
          <Typography
            fontSize={{ md: '18px', xs: '14px' }}
            fontWeight={400}
            lineHeight={'24px'}
            color={'#878698'}
          >
            *표시된 가격은 부가세가 포함된 금액입니다.
          </Typography>
        </Stack>
        <Stack direction={'row'} sx={{ mt: { md: '98px', xs: '79px' } }}>
          <Typography
            fontSize={{ md: '42px', xs: '24px' }}
            fontWeight={400}
            lineHeight={{ md: '50.12px', xs: '28.64px' }}
          >
            기본
          </Typography>
          <Typography
            fontSize={{ md: '42px', xs: '24px' }}
            fontWeight={700}
            lineHeight={{ md: '50.12px', xs: '28.64px' }}
            sx={{ ml: '10px' }}
          >
            요금 정보
          </Typography>
        </Stack>
        <Stack alignItems={'center'}>
          <Grid
            container
            spacing={2}
            mt={{ md: '40px', xs: '35px' }}
            mb={{ md: '120px', xs: '100px' }}
            width={{ md: '60%', xs: '100%' }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {checkItems?.map((item, idx) => (
              <Grid key={idx} item md={4} xs={6}>
                <CheckItemCard text={item} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </SectionContainer>
  );
};

const SubscriptionCard = (props) => {
  const { title, benefit, price, discount, originalPrice, btnText, index } =
    props;

  return index === 0 ? (
    <Stack
      sx={{
        width: '270px',
        height: '350px',
        background: 'rgba(244, 241, 255, 1)',
        borderRadius: '10px',
        boxShadow: '0 4px 10px rgba(183, 182, 193, 0.3)',
      }}
    >
      <Stack alignItems={'left'} sx={{ pl: '30px' }}>
        <Stack sx={{ pt: '40px' }}>
          <Typography
            fontSize={'18px'}
            fontWeight={700}
            lineHeight={'21.48px'}
            color={'#0E0D33'}
          >
            {title}
          </Typography>
        </Stack>
        <Stack sx={{ mt: '47px' }}>
          <Typography
            fontSize={'16px'}
            fontWeight={700}
            lineHeight={'19.09px'}
            color={'#441CF2'}
          >
            {benefit}
          </Typography>
        </Stack>
        <Stack flexDirection={'row'} alignItems={'center'} sx={{ mt: '7px' }}>
          <Box>
            <Typography
              fontSize={'30px'}
              fontWeight={700}
              lineHeight={'35.8px'}
              color={'#0E0D33'}
            >
              {price}
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <Stack alignItems={'center'}>
        <Stack
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            width: '210px',
            height: '44px',
            mt: '96px',
            borderRadius: '5px',
            background: '#441CF2',
          }}
          onClick={() => console.log('click')}
        >
          <Typography
            fontSize={'14px'}
            fontWeight={700}
            lineHeight={'16.17px'}
            color={'#FFFFFF'}
          >
            {btnText}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  ) : (
    <Stack
      sx={{
        width: '270px',
        height: '350px',
        background: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: '0 4px 10px rgba(183, 182, 193, 0.3)',
        border: '1px solid white',
        '&:hover': { borderColor: '#441CF2' },
      }}
    >
      <Stack alignItems={'left'} sx={{ pl: '30px' }}>
        <Stack sx={{ pt: '40px' }}>
          <Typography
            fontSize={'18px'}
            fontWeight={700}
            lineHeight={'21.48px'}
            color={'#0E0D33'}
          >
            {title}
          </Typography>
        </Stack>
        <Stack sx={{ mt: '47px' }}>
          <Typography
            fontSize={'16px'}
            fontWeight={700}
            lineHeight={'19.09px'}
            color={'#441CF2'}
          >
            {benefit}
          </Typography>
        </Stack>
        <Stack flexDirection={'row'} alignItems={'center'} sx={{ mt: '7px' }}>
          <Box>
            <Typography
              fontSize={'30px'}
              fontWeight={700}
              lineHeight={'35.8px'}
              color={'#0E0D33'}
            >
              {price}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            sx={{
              width: '42px',
              height: '24px',
              borderRadius: '30px',
              background: '#F4F1FF',
              m: '6px',
            }}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography
              fontSize={'12px'}
              fontWeight={700}
              lineHeight={'14.32px'}
              color={'#441CF2'}
            >
              {discount}
            </Typography>
          </Box>
        </Stack>
        <Stack sx={{ mt: '7px' }}>
          <Typography
            fontSize={'16px'}
            fontWeight={400}
            lineHeight={'19.09px'}
            color={'#878698'}
            style={{ textDecoration: 'line-through' }}
          >
            {originalPrice}
          </Typography>
        </Stack>
      </Stack>
      <Stack alignItems={'center'}>
        <Stack
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            width: '210px',
            height: '44px',
            mt: '70px',
            borderRadius: '5px',
            background: '#F4F6F9',
          }}
          onClick={() => console.log('click')}
        >
          <Typography
            fontSize={'14px'}
            fontWeight={700}
            lineHeight={'16.17px'}
            color={'#878698'}
          >
            {btnText}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const CheckItemCard = ({ text }) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box
        component={'img'}
        src={`${process.env.PUBLIC_URL}/images/price/check.png`}
      />
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ ml: { md: '18px', xs: '13px' } }}
      >
        <Typography
          fontSize={{ md: '16px', xs: '14px' }}
          fontWeight={700}
          lineHeight={'24px'}
          color={'#0E0D33'}
          width={'155px'}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default BasePrice;
