import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

const TitleContainer = ({ title, subTitle, desc, children, sx = {} }) => {
  return (
    <Box sx={sx}>
      <Stack alignItems={'center'} pt={{ md: '80px', xs: '30px' }}>
        <Stack>
          <Typography
            fontSize={{ md: '18px', xs: '14px' }}
            fontWeight={'700'}
            lineHeight={{ md: '21.48px', xs: '16.17px' }}
            color={'#441CF2'}
          >
            {subTitle}
          </Typography>
        </Stack>
        <Stack mt={'11px'}>
          <Typography
            fontSize={{ md: '42px', xs: '24px' }}
            fontWeight={700}
            lineHeight={{ md: '50.12px', xs: '28.64px' }}
            color={'#0E0D33'}
            whiteSpace={'pre-line'}
            textAlign={'center'}
          >
            {title}
          </Typography>
        </Stack>
        <Stack mt={'11px'}>
          <Typography
            fontSize={{ md: '16px', xs: '14px' }}
            fontWeight={400}
            color={'#B7B6C1'}
            whiteSpace={'pre-line'}
            textAlign={'center'}
          >
            {desc}
          </Typography>
        </Stack>
      </Stack>
      <Box justifyContent={'center'} mt={{ md: '55px', xs: '13px' }}>
        {children}
      </Box>
    </Box>
  );
};

export default TitleContainer;
