import React, { useEffect, useState } from 'react';
import SectionContainer from '../common/SectionContainer';
import TitleContainer from '../common/TitleContainer';
import {
  Box,
  Container,
  Stack,
  useTheme,
  useMediaQuery,
  Typography,
} from '@mui/material';

const images = [
  `${process.env.PUBLIC_URL}/images/easy/easy_img_01.png`,
  `${process.env.PUBLIC_URL}/images/easy/easy_img_02.png`,
  `${process.env.PUBLIC_URL}/images/easy/easy_img_03.png`,
];

const stepperData = [
  {
    imgs: [
      `${process.env.PUBLIC_URL}/images/easy/stepper_1_on.png`,
      `${process.env.PUBLIC_URL}/images/easy/stepper_1_off.png`,
    ],
    title: '상품 재고, 가격, 옵션 등을\n재수집할 수 있습니다.',
    desc: '수집사이트에서 변동된 상품명, 옵션/옵션명,\n가격,재고, 썸네일, 상세페이지의 업데이트 내역을\n재수집할 수 있습니다.',
  },
  {
    imgs: [
      `${process.env.PUBLIC_URL}/images/easy/stepper_2_on.png`,
      `${process.env.PUBLIC_URL}/images/easy/stepper_2_off.png`,
    ],
    title: '수집기 설정값에 맞게\n새로운 상품을 수집합니다.',
    desc: '수집기 설정 후, 설정값에 맞게 신규 상품을\n수집하고 상품 조회에서 조회할 수 있습니다.',
  },
  {
    imgs: [
      `${process.env.PUBLIC_URL}/images/easy/stepper_3_on.png`,
      `${process.env.PUBLIC_URL}/images/easy/stepper_3_off.png`,
    ],
    title: '수집하고 싶지 않은 상품은\n수집 제외가 가능합니다.',
    desc: '수집하고 싶지 않은 상품은 미완료 상품 목록에서\n수집 제외하시면 추후 수집할 때 해당 상품을\n                                     제외하고 수집합니다.',
  },
];

const Easy = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [curIdx, setCurIdx] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const startTimer = () => {
    const interval = setInterval(() => {
      setCurIdx((prev) => {
        if (prev === stepperData.length - 1) return 0;
        else return prev + 1;
      });
    }, 5000);

    return interval;
  };

  const stopTimer = () => {
    clearInterval(intervalId);
    setIntervalId(null);
  };

  const resetTimer = () => {
    stopTimer();
    const newIntervalId = startTimer();
    setIntervalId(newIntervalId);
  };

  useEffect(() => {
    const newIntervalId = startTimer();
    setIntervalId(newIntervalId);

    return () => {
      clearInterval(newIntervalId);
    };
  }, []);

  return (
    <Container maxWidth={false} sx={{ background: '#F4F6F9' }}>
      <SectionContainer>
        <TitleContainer
          title={'상품 수집 후, 언제든 재수집!'}
          subTitle={'Easy'}
        >
          {isMobile ? (
            <Stack mt={'10px'} pb={'67px'} alignItems={'center'}>
              {/* 상단이미지 */}
              <Box component={'img'} width={'90%'} src={images[curIdx]} />
              {/* 하단스태퍼 */}
              <Stack pt={'30px'} width={'90%'} marginX={'20px'}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  {stepperData?.map((data, idx) => (
                    <Box
                      key={idx}
                      component={'img'}
                      sx={{ width: '42px', cursor: 'pointer' }}
                      src={idx === curIdx ? data.imgs[0] : data.imgs[1]}
                      onClick={() => {
                        setCurIdx(idx);
                        resetTimer();
                      }}
                    />
                  ))}
                </Stack>
                <Stack mt={'31px'} alignItems={'center'}>
                  <Typography
                    fontSize={'20px'}
                    fontWeight={700}
                    lineHeight={'23.87px'}
                    whiteSpace={'pre-line'}
                    textAlign={'center'}
                  >
                    {stepperData[curIdx].title}
                  </Typography>
                  <Typography
                    mt={'18px'}
                    fontSize={'14px'}
                    fontWeight={400}
                    lineHeight={'20px'}
                    whiteSpace={'pre-line'}
                    textAlign={'center'}
                  >
                    {stepperData[curIdx].desc}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Box
              display={'flex'}
              mt={'77px'}
              pb={'200px'}
              justifyContent={'space-between'}
            >
              {/* 좌측이미지 */}
              <Box
                component={'img'}
                width={{ md: '550px', xs: '335px' }}
                src={images[curIdx]}
              />
              {/* 우측스태퍼 */}
              <Stack pt={'10px'}>
                <EasyStepper
                  data={stepperData}
                  index={curIdx}
                  setIdx={setCurIdx}
                  resetTimer={resetTimer}
                />
              </Stack>
            </Box>
          )}
        </TitleContainer>
      </SectionContainer>
    </Container>
  );
};

const EasyStepper = ({ data, index, setIdx, resetTimer }) => {
  return (
    <Stack height={'100%'} justifyContent={'space-between'}>
      {data?.map((info, idx) => {
        return (
          <EasyStepperCard
            key={idx}
            activeIdx={index}
            title={info.title}
            desc={info.desc}
            icon={index === idx ? info.imgs[0] : info.imgs[1]}
            idx={idx}
            setIdx={setIdx}
            resetTimer={resetTimer}
            sx={{ cursor: 'pointer' }}
          />
        );
      })}
    </Stack>
  );
};

const EasyStepperCard = (props) => {
  const { title, desc, icon, idx, setIdx, resetTimer, sx } = props;

  return (
    <Box display={'flex'} alignItems={'top'}>
      <Box
        component={'img'}
        src={icon}
        sx={{ ...sx, width: '42px', height: '42px' }}
        onClick={() => {
          setIdx(idx);
          resetTimer();
        }}
      />
      <Stack>
        <Typography
          fontSize={'24px'}
          fontWeight={700}
          lineHeight={'28.64px'}
          whiteSpace={'pre-line'}
          ml={'20px'}
        >
          {title}
        </Typography>
        <Typography
          fontSize={'14px'}
          fontWeight={400}
          lineHeight={'20px'}
          whiteSpace={'pre-line'}
          mt={'25px'}
          ml={'20px'}
          minWidth={'270px'}
        >
          {desc}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Easy;
