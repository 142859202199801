import React, { useEffect } from 'react';
import PageContainer from '../components/layout/PageContainer';
import BasePrice from '../components/price/BasePrice';
import ExtraService from '../components/price/ExtraService';

const PriceList = () => {
  useEffect(() => {
    const handleNavigate = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('beforeunload', handleNavigate);

    return () => {
      window.removeEventListener('beforeunload', handleNavigate);
    };
  }, []);
  return (
    <PageContainer>
      <BasePrice />
      <ExtraService />
    </PageContainer>
  );
};

export default PriceList;
