import React, { useEffect } from "react";
import PageContainer from "../../components/layout/PageContainer";
import SectionContainer from "../../components/common/SectionContainer";
import { Box, Stack, Typography } from '@mui/material';

const Privacy = () => {
  useEffect(() => {
    const handleNavigate = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("beforeunload", handleNavigate);

    return () => {
      window.removeEventListener("beforeunload", handleNavigate);
    };
  }, []);
  return (
    <PageContainer>
      <SectionContainer>
        <Stack sx={{ mt: "42px", marginX: "31px" }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>
              <Typography
                fontSize={"30px"}
                fontWeight={700}
                lineHeight={"28.64px"}
              >
                개인정보 처리방침
              </Typography>
            </Box>
          </Stack>
          {/* 여기부터 스크롤 적용 */}
          <Stack sx={{ mt: "30px", overflow: "auto" }}>
            <Stack>
              <Typography
                fontSize={"14px"}
                fontWeight={400}
                lineHeight={"16.71px"}
                color={"#000000"}
              >
                (주)나우스톤(이하 ‘나우스톤’)은 정보주체의 자유와 권리 보호를
                위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여,
                적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에
                「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에
                관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고
                원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
                처리방침을 수립·공개합니다.
              </Typography>
            </Stack>
            <Stack sx={{ mt: "40px" }}>
              <Typography
                fontSize={"16px"}
                fontWeight={700}
                lineHeight={"19.09px"}
                color={"#000000"}
              >
                제1조 (개인정보 수집에 대한 동의)
              </Typography>
            </Stack>
            <Stack sx={{ mt: "9px" }}>
              <Typography
                fontSize={"14px"}
                fontWeight={400}
                lineHeight={"16.71px"}
                color={"#000000"}
              >
                나우스톤은 고객님께서 개인정보처리방침 또는 이용약관의 내용에
                동의한다 또는 동의하지 않는다 버튼을 클릭할 수 있는 절차를
                마련하고 있으며, 동의한다 버튼을 클릭하면 개인정보 수집 에
                동의한 것으로 봅니다.
              </Typography>
            </Stack>
            <Stack sx={{ mt: "40px" }}>
              <Typography
                fontSize={"16px"}
                fontWeight={700}
                lineHeight={"19.09px"}
                color={"#000000"}
              >
                제2조 (개인정보의 수집,이용,제공에 대한 동의철회)
              </Typography>
            </Stack>
            <Stack sx={{ mt: "9px" }}>
              <div>
                <Typography
                  component={"pre"}
                  fontSize={"14px"}
                  fontWeight={400}
                  lineHeight={"16.71px"}
                  color={"#000000"}
                  whiteSpace={"pre-line"}
                >
                  1. 회원가입 등을 통한 개인정보의 수집, 이용, 제공과 관련해
                  귀하는 동의하신 내용을 언제든지 철회 하실 수 있습니다.
                  동의철회는 홈페이지 프로필 버튼을 누른 후 마이페이지의
                  회원정보 수정에서 "회원탈퇴"를 클릭하면 개인정보의
                  수집,이용,제공에 대한 동의를 바로 철회할 수 있으며,
                  개인정보보호책임자에게 서면, 전화, 이메일 등으로 연락하시면
                  회사는 즉시 회원 탈퇴를 위해 필요한 조치를 취합니다. 동의를
                  철회하고 개인정보를 파기하는 등의 조치가 있으면 그 사실을
                  귀하께 지체없이 통지하도록 하겠습니다. <br />
                  <br />
                  2. 나우스톤은 개인정보의 수집에 대한 동의철회(회원탈퇴)를
                  개인정보를 수집 하는 방법보다 쉽게 할 수 있도록 필요한 조치를
                  취합니다. <br />
                  <br />
                  3.SNS 계정을 통해 인증(로그인)한 고객은 회원정보 수정에서
                  SNS계정 연동해제를 할 수 있습니다. 또한 각 SNS 제공업체에서
                  SNS연동해제를 할 수 있습니다. 그러나 SNS 연동해제를 하더라도
                  나우스톤에 보관중인 고객정보가 삭제되는 것은 아니므로
                  회원탈퇴를 진행하셔야 개인정보가 파기됩니다.
                </Typography>
              </div>
            </Stack>
            <Stack sx={{ mt: "40px" }}>
              <Typography
                fontSize={"16px"}
                fontWeight={700}
                lineHeight={"19.09px"}
                color={"#000000"}
              >
                제3조 (개인정보의 처리 목적)
              </Typography>
            </Stack>
            <Stack sx={{ mt: "9px" }}>
              <div>
                <Typography
                  component={"pre"}
                  fontSize={"14px"}
                  fontWeight={400}
                  lineHeight={"16.71px"}
                  color={"#000000"}
                  whiteSpace={"pre-line"}
                >
                  1. 회원가입 등을 통한 개인정보의 수집, 이용, 제공과 관련해
                  귀하는 동의하신 내용을 언제든지 철회 하실 수 있습니다.
                  동의철회는 홈페이지 프로필 버튼을 누른 후 마이페이지의
                  회원정보 수정에서 "회원탈퇴"를 클릭하면 개인정보의
                  수집,이용,제공에 대한 동의를 바로 철회할 수 있으며,
                  개인정보보호책임자에게 서면, 전화, 이메일 등으로 연락하시면
                  회사는 즉시 회원 탈퇴를 위해 필요한 조치를 취합니다. 동의를
                  철회하고 개인정보를 파기하는 등의 조치가 있으면 그 사실을
                  귀하께 지체없이 통지하도록 하겠습니다. <br />
                  <br />
                  2. 나우스톤은 개인정보의 수집에 대한 동의철회(회원탈퇴)를
                  개인정보를 수집 하는 방법보다 쉽게 할 수 있도록 필요한 조치를
                  취합니다. <br />
                  <br />
                  3.SNS 계정을 통해 인증(로그인)한 고객은 회원정보 수정에서
                  SNS계정 연동해제를 할 수 있습니다. 또한 각 SNS 제공업체에서
                  SNS연동해제를 할 수 있습니다. 그러나 SNS 연동해제를 하더라도
                  나우스톤에 보관중인 고객정보가 삭제되는 것은 아니므로
                  회원탈퇴를 진행하셔야 개인정보가 파기됩니다.
                </Typography>
              </div>
            </Stack>
          </Stack>
        </Stack>
      </SectionContainer>
    </PageContainer>
  );
};

export default Privacy;
