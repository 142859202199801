import { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SimpleDrawer from "./Drawer";

// 웹 메뉴
const navMenuItems = [
  { key: "introduction", name: "서비스 소개", path: "https://spresso.kr/" },
  {
    key: "guide",
    name: "사용가이드",
    path: "https://nowstone.notion.site/Spresso-e408d78543804ecd8aa2d00cf5ca67fd",
  },
  { key: "price", name: "요금안내", path: "https://spresso.kr/price" },
  { key: "login", name: "로그인", path: "https://spresso.kr/login" },
  {
    key: "free_ex",
    img: `${process.env.PUBLIC_URL}/images/free_ex_appbar.png`,
    path: "/login",
  },
];

const Appbar = (props) => {
  const {
    position = "sticky",
    sx,
    appbarBlack,
    clickButton,
    ...others
  } = props;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigation = useNavigate();

  // appbar 배경 컬러변경
  const [isScroll, setIsScroll] = useState(false);

  const handleClickLogin = () => {
    window.location.href = "https://console.spresso.kr/login";
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScroll(true);
      } else if (window.scrollY === 0) {
        setIsScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <AppBar
        position={isMobile ? "relative" : position}
        elevation={0}
        sx={{
          zIndex: 999,
          top: 0,
          ...sx,
          backgroundColor: isScroll ? "white" : "transparent",
          transition: "background 0.4s",
          // borderBottom: `1px solid gray`,
        }}
        {...others}
      >
        <Container
          disableGutters
          sx={{
            position: "relative",
            padding: { lg: "0", sm: "0 24px", xs: "0 16px" },
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            height={{ md: "80px", sx: "60px" }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              onClick={() => {
                navigation("/");
                window.location.reload();
              }}
              sx={{ cursor: "pointer" }}
            >
              <Box
                component={"img"}
                src={`${process.env.PUBLIC_URL}/images/main_logo.png`}
              ></Box>
            </Box>
            <Box display={"flex"}>
              {isMobile ? (
                <SimpleDrawer />
              ) : (
                navMenuItems?.map((item, idx) => (
                  <Button key={idx}>
                    {item.key === "login" ? (
                      <Typography
                        fontSize={"14px"}
                        fontWeight={700}
                        lineHeight={"16.71px"}
                        color={"#0E0D33"}
                        onClick={handleClickLogin}
                      >
                        {item.name}
                      </Typography>
                    ) : item.key === "free_ex" ? (
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                          width: "120px",
                          height: "36px",
                          background: "#441CF2",
                          borderRadius: "30px",
                        }}
                        onClick={handleClickLogin}
                      >
                        <Typography
                          fontSize={"14px"}
                          fontWeight={400}
                          lineHeight={"16.71px"}
                          color={"#ffffff"}
                        >
                          2주 무료체험
                        </Typography>
                      </Box>
                    ) : (
                      <Typography
                        fontSize={"14px"}
                        fontWeight={400}
                        lineHeight={"16.71px"}
                        color={"#0E0D33"}
                        onClick={() => (window.location.href = item.path)}
                      >
                        {item.name}
                      </Typography>
                    )}
                  </Button>
                ))
              )}
            </Box>
          </Box>
        </Container>
      </AppBar>
    </>
  );
};
export default Appbar;
