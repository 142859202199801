import React from 'react';
import TitleContainer from '../common/TitleContainer';
import SectionContainer from '../common/SectionContainer';
import ImageCard from '../common/ImageCard';
import { Grid } from '@mui/material';

const dataList = [
  {
    img: `${process.env.PUBLIC_URL}/images/mainfacility/additem.png`,
    title: '수집기 기능',
    desc: '키워드별로 묶어서\n수집 상품 관리 가능',
  },
  {
    img: `${process.env.PUBLIC_URL}/images/mainfacility/convertshape-2.png`,
    title: '카테고리 매핑',
    desc: '간편한 마켓별\n카테고리 매핑',
  },
  {
    img: `${process.env.PUBLIC_URL}/images/mainfacility/wallet.png`,
    title: '통합 정책',
    desc: '마진, 마켓, 상품/옵션명,\n상세페이지 정책을 한번에',
  },
  {
    img: `${process.env.PUBLIC_URL}/images/mainfacility/box-add.png`,
    title: '상품 관리',
    desc: '상품/옵션명을 수정(번역)하고\n가격, 재고를 재수집 및 관리',
  },
  {
    img: `${process.env.PUBLIC_URL}/images/mainfacility/3square.png`,
    title: '마켓별 복수 계정',
    desc: '마켓별 여러개의\n계정을 지원',
  },
  {
    img: `${process.env.PUBLIC_URL}/images/mainfacility/device-message.png`,
    title: '주문/CS 관리 기능',
    desc: '모든 마켓의 주문, 문의를\n수집하여 관리',
  },
  {
    img: `${process.env.PUBLIC_URL}/images/mainfacility/filter-remove.png`,
    title: '금지어 설정',
    desc: '금지어를 설정하고\n단어 치환 가능',
  },
  {
    img: `${process.env.PUBLIC_URL}/images/mainfacility/magicpen.png`,
    title: '이미지 편집 (준비중)',
    desc: '이미지를 깔끔하게\n번역 및 편집',
  },
];

const MainFacility = () => {
  return (
    <SectionContainer>
      <TitleContainer title={'Spresso 대표기능'} subTitle={'Main Facility'}>
        <Grid container spacing={2} pb={{ md: '170px', xs: '100px' }}>
          {dataList?.map((data, idx) => (
            <Grid
              key={idx}
              item
              xs={6}
              md={3}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <ImageCard img={data.img} title={data.title} desc={data.desc} />
            </Grid>
          ))}
        </Grid>
      </TitleContainer>
    </SectionContainer>
  );
};

export default MainFacility;
