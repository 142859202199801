import TestAppbar from './Appbar';
import Footer from './Footer';
import { Box } from '@mui/material';

const PageContainer = ({
  children,
  sx,
  position,
  appbarBlack = true,
  clickButton,
}) => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TestAppbar
        sx={{ ...sx }}
        position={position}
        appbarBlack={appbarBlack}
        clickButton={clickButton}
      />
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: 'white',
          // display: pageCenter && 'flex',
          // justifyContent: pageCenter && 'center',
          // alignItems: pageCenter && 'center',
          // ...sx,
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default PageContainer;
