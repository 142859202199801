import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

// 웹 메뉴
const menu_1 = [
  { key: "introduction", name: "서비스 소개", path: "https://spresso.kr/" },
  {
    key: "guide",
    name: "사용가이드",
    path: "https://nowstone.notion.site/Spresso-e408d78543804ecd8aa2d00cf5ca67fd",
  },
  { key: "price", name: "요금안내", path: "https://spresso.kr/price" },
];

const menu_2 = [
  { key: "login", name: "로그인", path: "https://spresso.kr/login" },
  {
    key: "free_ex",
    img: `${process.env.PUBLIC_URL}/images/free_ex_appbar.png`,
    path: "https://spresso.kr/login",
  },
];

export default function SimpleDrawer() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleClickLogin = () => {
    window.location.href = "https://console.spresso.kr/login";
  };

  const DrawerList = (
    <Box sx={{ width: 300 }} role="presentation" onClick={toggleDrawer(false)}>
      <List sx={{ pt: "50px", pl: "30px" }}>
        {menu_1?.map((item, idx) => (
          <ListItem key={idx} disablePadding>
            <ListItemButton onClick={() => (window.location.href = item.path)}>
              <ListItemText>
                <Typography
                  fontSize={"16px"}
                  fontWeight={400}
                  lineHeight={"19.09px"}
                >
                  {item.name}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
        <Divider sx={{ pt: "50px" }} />
        {menu_2?.map((item, idx) => (
          <ListItem key={idx} disablePadding sx={{ pt: "30px" }}>
            {item.key === "login" ? (
              <ListItemButton onClick={handleClickLogin}>
                <ListItemText>
                  <Typography
                    fontSize={"16px"}
                    fontWeight={400}
                    lineHeight={"19.09px"}
                  >
                    {item.name}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            ) : (
              item.key === "free_ex" && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    width: "240px",
                    height: "36px",
                    background: "#441CF2",
                    borderRadius: "30px",
                  }}
                  onClick={handleClickLogin}
                >
                  <Box component={"img"} src={item.img} />
                </Box>
              )
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <IconButton onClick={toggleDrawer(true)}>{<MenuIcon />}</IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        {DrawerList}
      </Drawer>
    </div>
  );
}
