import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Modal from '../Modal';
import { modalAtom } from '../../../recoil/modalAtom';
import { useSetRecoilState } from 'recoil';

const TermsModal = () => {
  const setModal = useSetRecoilState(modalAtom);

  return (
    <Modal
      sx={{
        width: '500px',
        height: '700px',
        background: '#ffffff',
        borderRadius: '10px',
      }}
    >
      <Stack sx={{ mt: '42px', marginX: '31px' }}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box>
            <Typography
              fontSize={'24px'}
              fontWeight={700}
              lineHeight={'28.64px'}
            >
              서비스 이용약관
            </Typography>
          </Box>
          <Box
            component={'img'}
            src={`${process.env.PUBLIC_URL}/images/Vector.png`}
            sx={{ width: '18px', height: '18px', cursor: 'pointer' }}
            onClick={() => setModal((prev) => ({ ...prev, terms: false }))}
          />
        </Stack>
        {/* 여기부터 스크롤 적용 */}
        <Stack sx={{ mt: '25px', overflow: 'auto', maxHeight: '570px' }}>
          <Stack>
            <Typography
              fontSize={'16px'}
              fontWeight={700}
              lineHeight={'19.09px'}
              color={'#000000'}
            >
              제1조 (목적)
            </Typography>
          </Stack>
          <Stack sx={{ mt: '10px' }}>
            <Typography
              fontSize={'14px'}
              fontWeight={400}
              lineHeight={'16.71px'}
              color={'#000000'}
            >
              이 약관은 (주)나우스톤 (이하 "회사")가 제공하는 아임웹 서비스 및
              관련 제반 서비스(이하 "서비스")의 이용과 관련하여 회사와 회원과의
              권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로
              합니다.
            </Typography>
          </Stack>
          <Stack sx={{ mt: '13px' }}>
            <Typography
              fontSize={'16px'}
              fontWeight={700}
              lineHeight={'19.09px'}
              color={'#000000'}
            >
              제2조 (정의)
            </Typography>
          </Stack>
          <Stack sx={{ mt: '9px' }}>
            <div>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'16.71px'}
                color={'#000000'}
              >
                이 약관에서 사용하는 용어는 아래와 같습니다. <br />
                1. "회원"이라 함은 회사의 "서비스"에 접속하여 이 약관에 따라
                "회사"와 이용계약을 체결하고 "회사"가 제공하는 "서비스"를
                이용하는 고객을 말합니다.
                <br />
                <br />
                2. "아이디(ID)"라 함은 "회원"의 식별과 서비스 이용을 위하여
                "회원"이 정하고 "회사"가 승인하는 문자와 숫자의 조합을
                의미합니다. <br />
                <br />
                3. "비밀번호"라 함은 "회원"이 부여 받은 "아이디와 일치되는
                "회원"임을 확인하고 비밀보호를 위해 "회원" 자신이 정한 문자 또는
                숫자의 조합을 의미합니다. 4. "유료서비스"라 함은 "서비스"중
                "회사"가 유료로 제공하는 각종 웹사이트 구축 서비스 및 제반
                서비스를 의미합니다. <br />
                <br />
                5. "게시물" 혹은 "콘텐츠"이라 함은 회원이 서비스를 이용함에 있어
                서비스상에 게시한 부호ᆞ문자ᆞ음성ᆞ음향ᆞ화상ᆞ동영상 등의 정보
                형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
              </Typography>
            </div>
          </Stack>
          <Stack sx={{ mt: '28px' }}>
            <Typography
              fontSize={'16px'}
              fontWeight={700}
              lineHeight={'19.09px'}
              color={'#000000'}
            >
              제3조 (약관의 게시와 개정)
            </Typography>
          </Stack>
          <Stack sx={{ mt: '9px' }}>
            <div>
              <Typography
                component={'pre'}
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'16.71px'}
                color={'#000000'}
                whiteSpace={'pre-line'}
              >
                1. "회사"는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 서비스
                초기 화면에 게시합니다. <br />
                2. "회사"는
                "약관의규제에관한법률","정보통신망이용촉진및정보보호에관한법률(이하
                "정보통신망법")" 등 관련법을 위배하지 않는 범위에서 이 약관을
                개정할 수 있습니다.
              </Typography>
            </div>
          </Stack>
          <Stack sx={{ mt: '28px' }}>
            <Typography
              fontSize={'16px'}
              fontWeight={700}
              lineHeight={'19.09px'}
              color={'#000000'}
            >
              제4조 (약관의 해석)
            </Typography>
          </Stack>
          <Stack sx={{ mt: '9px' }}>
            <div>
              <Typography
                component={'pre'}
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'16.71px'}
                color={'#000000'}
                whiteSpace={'pre-line'}
              >
                1.회사는 개별 서비스에 대해서는 별도의 이용약관 및 정책("기타
                약관 등"이라 함)을 둘 수 있으며, 해당 내용이 이 약관과 상충할
                경우에는 "기타 약관 등"이 우
              </Typography>
            </div>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default TermsModal;
