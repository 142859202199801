import { Box } from '@mui/material';
import React from 'react';

const Modal = ({ children, sx }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Modal;
