import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { modalAtom } from "../../recoil/modalAtom";

const companyInfo = {
  main: "(주)나우스톤",
  subList: [
    { text: `사업자 등록번호: 150-87-02527 | 대표: 최현재, 최원석` },
    { text: "통신판매업 신고번호: 제2024-서울금천-0624호" },
    { text: "오피스 : 서울 금천구 디지털로9길 99 스타밸리 1401호" },
    { text: "유선번호 : 010-5904-9757" },
  ],
};

const policy = {
  id: "policy",
  main: "정책",
  subList: [
    { id: "privacy", text: "개인정보처리방침" },
    { id: "terms", text: "이용약관" },
  ],
};

const cs = {
  main: "고객센터",
  subList: [{ text: "support@spresso.io" }, { text: "카카오톡 문의" }],
};

const info = {
  main: "안내",
  subList: [{ text: "에스프레소 이용가이드" }, { text: "카카오톡 오픈채팅방" }],
};

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const setModal = useSetRecoilState(modalAtom);

  return isMobile ? (
    <Container
      maxWidth={"false"}
      sx={{
        background: "#0E0D33",
        height: "auto",
        flexShrink: 0,
        borderTop: "1px solid rgba(0, 10, 16, 0.1)",
      }}
      style={{ display: "flex", justifyContent: "left" }}
    >
      <Stack sx={{ mt: "50px", ml: "30px" }}>
        <Stack>
          <Stack
            component={"img"}
            maxWidth={"120px"}
            src={`${process.env.PUBLIC_URL}/images/main_logo_white.png`}
            sx={{ cursor: "pointer" }}
          />
        </Stack>
        <Stack direction={"row"}>
          <Stack>
            <FooterTypography
              id={policy.id}
              main={policy.main}
              subList={policy.subList}
              flex={1}
              setModal={setModal}
              cursor={"pointer"}
            />
          </Stack>
          <Stack sx={{ ml: "74px" }}>
            <FooterTypography main={cs.main} subList={cs.subList} flex={1} />
          </Stack>
        </Stack>
        <Stack>
          <FooterTypography main={info.main} subList={info.subList} flex={1} />
        </Stack>
        <Stack>
          <FooterTypography
            main={companyInfo.main}
            subList={companyInfo.subList}
            flex={3}
          />
        </Stack>
        <Typography
          fontSize={"12px"}
          fontWeight={400}
          lineHeight={"14.32px"}
          color={"rgba(231, 231, 235, 0.8)"}
          mt={"30px"}
          mb={"50px"}
          ml={"2px"}
        >
          © 2024 by Spresso
        </Typography>
      </Stack>
    </Container>
  ) : (
    <Container
      maxWidth={"false"}
      sx={{
        background: "#0E0D33",
        height: "auto",
        flexShrink: 0,
        padding: "28px 24px",
        borderTop: "1px solid rgba(0, 10, 16, 0.1)",
      }}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Stack
        maxWidth="lg"
        sx={{
          width: "100%",
          mt: "80px",
        }}
      >
        <Stack
          component={"img"}
          maxWidth={"120px"}
          src={`${process.env.PUBLIC_URL}/images/main_logo_white.png`}
        />
        <Box display={"flex"} justifyContent={"space-between"}>
          <FooterTypography
            main={companyInfo.main}
            subList={companyInfo.subList}
            flex={3}
          />
          <FooterTypography
            id={policy.id}
            main={policy.main}
            subList={policy.subList}
            flex={1}
            setModal={setModal}
            cursor={"pointer"}
          />
          <FooterTypography main={cs.main} subList={cs.subList} flex={1} />
          <FooterTypography main={info.main} subList={info.subList} flex={1} />
        </Box>
        <Typography
          fontSize={"12px"}
          fontWeight={400}
          lineHeight={"14.32px"}
          color={"rgba(231, 231, 235, 0.8)"}
          mt={"30px"}
          mb={"60px"}
        >
          © 2024 by Spresso
        </Typography>
      </Stack>
    </Container>
  );
};

const FooterTypography = ({
  id,
  main,
  subList,
  sx,
  flex,
  setModal,
  cursor,
}) => {
  const navigation = useNavigate();
  const handleClick = (idx) => {
    if (id !== "policy") return;
    if (idx === 0) {
      navigation("/policy/privacy");
    } else {
      setModal((prev) => ({ ...prev, terms: true }));
    }
  };

  return (
    <Stack flex={flex} sx={{ ...sx }}>
      <Typography
        fontSize={"12px"}
        fontWeight={700}
        lineHeight={"14.32px"}
        color={"white"}
        mt={"30px"}
      >
        {main}
      </Typography>
      {!isEmpty(subList) &&
        [...Array(subList?.length)].map((_, idx) => {
          return (
            <Typography
              key={idx}
              fontSize={"12px"}
              fontWeight={400}
              lineHeight={"14.32px"}
              color={"rgba(231, 231, 235, 0.8)"}
              mt={idx === 0 ? { md: "20px", xs: "11px" } : "13px"}
              whiteSpace={"pre-line"}
              onClick={() => handleClick(idx)}
              sx={{ cursor: cursor }}
            >
              {subList[idx].text}
            </Typography>
          );
        })}
    </Stack>
  );
};
export default Footer;
