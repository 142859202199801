import { Box, Container, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import LogoCard from "../common/LogoCard";
import SectionContainer from "../common/SectionContainer";
import TitleContainer from "../common/TitleContainer";

const curSiteLogos = [
  `${process.env.PUBLIC_URL}/images/quality/ebay_s.png`,
  `${process.env.PUBLIC_URL}/images/quality/amazon_s.png`,
  `${process.env.PUBLIC_URL}/images/quality/temu_s.png`,
  `${process.env.PUBLIC_URL}/images/quality/china_s.png`,
];

const addSiteLogos = [
  `${process.env.PUBLIC_URL}/images/quality/aliexpress_logo.png`,
  `${process.env.PUBLIC_URL}/images/quality/iherb_logo.png`,
  `${process.env.PUBLIC_URL}/images/quality/ople_logo.png`,
  `${process.env.PUBLIC_URL}/images/quality/macys_logo.png`,
];

const connectableMarketLogos = [
  `${process.env.PUBLIC_URL}/images/quality/coupang_m.png`,
  `${process.env.PUBLIC_URL}/images/quality/11_street_m.png`,
  `${process.env.PUBLIC_URL}/images/quality/naver_m.png`,
];

const addMarketLogos = [
  `${process.env.PUBLIC_URL}/images/quality/g_market_m.png`,
  `${process.env.PUBLIC_URL}/images/quality/auction_m.png`,
  `${process.env.PUBLIC_URL}/images/quality/lotte_on_m.png`,
  `${process.env.PUBLIC_URL}/images/quality/interpark_m.png`,
  `${process.env.PUBLIC_URL}/images/quality/wemep_m.png`,
  `${process.env.PUBLIC_URL}/images/quality/kakao_talk.png`,
];

const Quality = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container maxWidth={false} sx={{ background: "#F4F6F9" }}>
      <SectionContainer>
        {isMobile ? (
          <TitleContainer
            title={"다양한 오픈마켓과\n수집사이트 지원"}
            subTitle={"Quality"}
            desc={"마켓 및 수집사이트는 계속해서 추가될 예정입니다."}
          >
            <Stack
              sx={{
                pt: "30px",
                pb: "100px",
                minHeight: "730px",
                justifyContent: "space-between",
              }}
            >
              <LogoCard
                title={"현재 가능한 수집사이트"}
                titleImages={curSiteLogos}
                subTitle={"추가될 수집사이트"}
                subTitleImages={addSiteLogos}
                sx={{ background: "#FFFFFF", borderRadius: "10px" }}
              />
              <LogoCard
                title={"연결 가능 오픈 마켓"}
                titleImages={connectableMarketLogos}
                subTitle={"추가될 오픈 마켓"}
                subTitleImages={addMarketLogos}
                sx={{ background: "#FFFFFF", borderRadius: "10px" }}
              />
            </Stack>
          </TitleContainer>
        ) : (
          <TitleContainer
            title={"다양한 오픈마켓과 수집사이트 지원"}
            subTitle={"Quality"}
            desc={"마켓 및 수집사이트는 계속해서 추가될 예정입니다."}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              pb={{ md: "200px", xs: "100px" }}
            >
              <LogoCard
                title={"현재 가능한 수집사이트"}
                titleImages={curSiteLogos}
                subTitle={"추가될 수집사이트"}
                subTitleImages={addSiteLogos}
                sx={{ background: "#FFFFFF", borderRadius: "10px" }}
              />
              <LogoCard
                title={"연결 가능 오픈 마켓"}
                titleImages={connectableMarketLogos}
                subTitle={"추가될 오픈 마켓"}
                subTitleImages={addMarketLogos}
                sx={{ background: "#FFFFFF", borderRadius: "10px" }}
              />
            </Box>
          </TitleContainer>
        )}
        {/* <TitleContainer
          title={'다양한 오픈마켓과 수집사이트 지원'}
          subTitle={'Quality'}
          desc={'마켓 및 수집사이트는 계속해서 추가될 예정입니다.'}
        >
          {isMobile ? (
            <Stack
              sx={{
                pt: '30px',
                pb: '100px',
                minHeight: '730px',
                justifyContent: 'space-between',
              }}
            >
              <LogoCard
                title={'현재 가능한 수집사이트'}
                titleImages={curSiteLogos}
                subTitle={'추가될 수집사이트'}
                subTitleImages={addSiteLogos}
                sx={{ background: '#FFFFFF', borderRadius: '10px' }}
              />
              <LogoCard
                title={'연결 가능 오픈 마켓'}
                titleImages={connectableMarketLogos}
                subTitle={'추가될 오픈 마켓'}
                subTitleImages={addMarketLogos}
                sx={{ background: '#FFFFFF', borderRadius: '10px' }}
              />
            </Stack>
          ) : (
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              pb={{ md: '200px', xs: '100px' }}
            >
              <LogoCard
                title={'현재 가능한 수집사이트'}
                titleImages={curSiteLogos}
                subTitle={'추가될 수집사이트'}
                subTitleImages={addSiteLogos}
                sx={{ background: '#FFFFFF', borderRadius: '10px' }}
              />
              <LogoCard
                title={'연결 가능 오픈 마켓'}
                titleImages={connectableMarketLogos}
                subTitle={'추가될 오픈 마켓'}
                subTitleImages={addMarketLogos}
                sx={{ background: '#FFFFFF', borderRadius: '10px' }}
              />
            </Box>
          )}
        </TitleContainer> */}
      </SectionContainer>
    </Container>
  );
};

export default Quality;
